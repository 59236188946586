<template>
  <button class="button">
    <slot></slot>
  </button>
</template>

<script></script>

<style lang="scss" scoped>
.button {
  background: linear-gradient(270deg, #6a45ff 0%, #a38cff 100%);
  height: 81px;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 23px 0;
  border-radius: 60px;
  color: $white;
  font-size: 12px;
  text-transform: uppercase;
  @include sm {
    height: 53px;
  }
}
</style>
