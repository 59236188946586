export function throttle(mainFunction: Function, delay: number) {
  let timerFlag: any = null

  return (...args: any) => {
    if (timerFlag !== null) return
    mainFunction(...args)

    timerFlag = setTimeout(() => {
      timerFlag = null
    }, delay)
  }
}
