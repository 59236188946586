<template>
  <div
    v-if="isVisible"
    class="modal-container"
    :class="{ 'modal-container--active': isVisible }"
  >
    <div class="modal-container__inner">
      <div
        ref="modalContent"
        class="modal-container__content"
        :class="{ 'modal-container__content--active': isVisible }"
        @click="(e) => handleClickOutside(e)"
      >
        <button class="modal-container__close-button" @click="closeModal">
          <NuxtImg src="/img/icon_modal_close.svg" />
        </button>
        <slot></slot>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  onClose: {
    type: Function,
    required: true,
  },
})

const modalContent = ref<Element>()

const handleClickOutside = (event: Event) => {
  return null
  // if (
  //   !modalContent.value ||
  //   !event.target ||
  //   modalContent.value.contains(event.target as Node)
  // )
  //   return

  // props.onClose(false)
}

const closeModal = () => {
  props.onClose(false)
}

onBeforeUnmount(() => {
  closeModal()
})

watch(
  () => props.isVisible,
  (newVal) => {
    if (newVal) {
      document.body.classList.add("no-scroll")
    } else {
      document.body.classList.remove("no-scroll")
    }
  },
)
</script>

<style scoped lang="scss">
.modal-container {
  width: 100vw;
  height: 100vh;
  background-color: rgba(0, 0, 0, 0.3);
  position: fixed;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 0;
  transition: 0.335s;
  pointer-events: none;
  z-index: 10;

  &--active {
    opacity: 1;
    pointer-events: all;
  }

  &__inner {
    max-width: 600px;
    width: 100%;
    padding: 0 20px;

    @include sm {
      padding: 0;
    }
  }

  &__content {
    position: relative;
    transition: 0.335s;
    transform: scale(0.5);
    display: flex;
    flex-direction: column;
    min-height: 10dvh;
    background: $color-light-gray;
    border-radius: 20px;
    z-index: 10;
    &--active {
      transform: scale(1);
    }

    @include sm {
      height: 100vh;
      border-radius: 0;
    }
    &::-webkit-scrollbar,
    &::-webkit-scrollbar-thumb,
    &::-webkit-scrollbar-track {
      display: none;
    }

    // &::-webkit-scrollbar {
    //   width: 6px;
    // }

    // &::-webkit-scrollbar-thumb {
    //   background: $black;
    //   border-radius: 10px;
    // }

    // &::-webkit-scrollbar-track {
    //   border-radius: 10px;
    // }
  }

  &__close-button {
    background: none;
    border: none;
    position: fixed;
    display: flex;
    align-items: center;
    justify-content: center;
    right: 10px;
    top: 10px;
    cursor: pointer;
    z-index: 10;
    width: 32px;
    height: 32px;
    opacity: 0.5;
    transition: ease-in-out 0.335s;
    img {
      width: 15px;
      height: 15px;
    }
    &:hover {
      opacity: 1;
    }
  }
}
</style>
