<template>
  <HeaderBlock />
  <div class="page-404">
    <div class="page-404__wrapper">
      <div class="text-44 text-bold">Ошибка 404</div>
      <p class="page-404__description" style="text-align: center">
        К сожалению, ссылка, по которой<br />
        вы пытаетесь перейти, <br />
        недействительна
      </p>
      <UiSliderButton
        text="на главную"
        class="page-404__button"
        @click="goBack"
      />
    </div>
  </div>
</template>
<script setup lang="ts">
const router = useRouter()
function goBack() {
  router.push({ path: "/" })
}
</script>
<style lang="scss" scoped>
.page-404 {
  position: relative;
  padding: 60px 70px;
  width: 100vw;
  height: 100vh;
  background-color: #0e0e0e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  color: #ffffff;
  overflow: hidden;
  &::before {
    content: url("@/public/img/bg_404.png");
    position: absolute;
    display: block;
    top: 0;
    right: 0;
    width: 70%;
    height: 70%;
    opacity: 0.7;
  }
  &__wrapper {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  &__description {
    margin: 30px 0 0 0;
    text-align: center;
    font-size: 16px;
    line-height: 20px;
  }
  &__button {
    position: absolute;
    bottom: 0;
  }
}
</style>
