<template>
  <div class="input">
    <input
      ref="inputRef"
      v-maska
      data-maska="+7 (###) ###-##-##"
      :data-maska-tokens="{ '#': { pattern: /[0-9]/ } }"
      type="tel"
      :placeholder="placeholder"
      @input="updateValue"
    />
  </div>
</template>

<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: true,
  },
  modelValue: String,
})

const inputRef = ref(null)
// const phoneMask = "+7 (###) ###-##-##"

const emit = defineEmits(["update:modelValue"])

const updateValue = (event) => {
  emit("update:modelValue", event.target.value)
}
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 6px;
  padding: 0 32px;
  font-size: 12px;
  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 23px;
    @media screen and (max-width: 460px) {
      font-size: 16px;
    }
  }

  &__password {
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  @include sm {
    padding: 18px 20px;
    height: 60px;
  }
}
</style>
