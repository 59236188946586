<template>
  <div class="custom-select" @click="toggleDropdown">
    <div class="custom-select__header">
      <p>{{ selectedText }}</p>
      <NuxtImg
        src="/img/icon_arrow_down.svg"
        class="arrow"
        :class="{ open: isOpen }"
      />
    </div>
    <ul
      ref="dropdownList"
      class="custom-select__list"
      :class="{ open: isOpen }"
    >
      <li
        v-for="option in options"
        :key="option.id"
        class="custom-select__item"
        @click.stop="toggleSelection(option)"
      >
        <div class="custom-checkbox">
          <span class="custom-checkbox__label">
            {{ option.attributes.shortTitle }}
          </span>
        </div>
      </li>
    </ul>
  </div>
</template>

<script setup lang="ts">
const props = defineProps({
  options: {
    type: Array,
    required: true,
  },
  maxSelections: {
    type: Number,
    default: 1,
  },
  modelValue: {
    type: Array,
    default: null,
  },
  placeholder: String,
})
const router = useRouter()
const emit = defineEmits(["update:modelValue", "change"])
const selectedOption = ref<any | null>(props.modelValue)
const isOpen = ref(false)
const dropdownList = ref(null)

const toggleDropdown = (event) => {
  isOpen.value = !isOpen.value
  event.stopPropagation()
}

const handleClickOutside = (event) => {
  if (dropdownList.value && !dropdownList.value.contains(event.target)) {
    isOpen.value = false
  }
}

const toggleSelection = (option) => {
  selectedOption.value = option
  emit("update:modelValue", selectedOption.value)
  emit("change", selectedOption.value)
  isOpen.value = false
  router.push(getNominationLink(selectedOption.value.attributes.slug))
}

const selectedText = computed(() => {
  return selectedOption.value?.attributes?.shortTitle || props.placeholder
})

watch(
  () => props.modelValue,
  (newVal) => {
    selectedOption.value = newVal
  },
)

onMounted(() => {
  document.addEventListener("click", handleClickOutside)
})

onBeforeUnmount(() => {
  document.removeEventListener("click", handleClickOutside)
})
</script>

<style scoped lang="scss">
.custom-select {
  position: relative;
  display: inline-block;
  max-width: 260px;
  width: 100%;
  border-radius: 6px;

  &__header {
    cursor: pointer;
    user-select: none;
    height: 48px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 0 20px;
    font-size: 12px;
    border-radius: 40px;
    border: 1px solid;
    .arrow {
      height: 10px;
      transition: 0.2s;
      &.open {
        transform: rotate(-180deg);
      }
    }

    p {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
      margin: 0;
      font-size: 12px;
    }
  }

  &__list {
    position: absolute;
    top: calc(100% + 11px);
    width: 100%;
    border-radius: 6px;
    max-height: 468px;
    overflow-y: auto;
    background-color: #fff;
    z-index: 1000;
    display: none;
    flex-direction: column;
    gap: 10px;
    padding: 20px;
    font-size: 12px;
    &.open {
      display: flex;
    }
  }

  @include sm {
    &__header {
      height: 60px;
      padding: 18px 20px;
    }
    &__list {
      padding: 18px 20px;
    }
  }

  &__item {
    cursor: pointer;
    color: #8c8c8c;
    &:hover {
      color: $primary-black;
    }
  }
}

.custom-checkbox {
  &__label {
    cursor: pointer;

    @include sm {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
}
</style>
