<template>
  <div class="authorization">
    <component
      :is="currentComponent"
      @change-view="changeView"
      @close="closeModal"
    >
    </component>
  </div>
</template>

<script setup>
import {
  HeaderAuthorizationSignIn,
  HeaderAuthorizationSignUp,
  HeaderAuthorizationRecovery,
  HeaderAuthorizationNewPassword,
} from "#components"

const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
  view: {
    type: String,
    required: false,
    default: "login",
  },
  close: {
    type: Function,
    required: true,
  },
})

const currentView = ref(props.view)
const components = {
  login: HeaderAuthorizationSignIn,
  register: HeaderAuthorizationSignUp,
  forgotPassword: HeaderAuthorizationRecovery,
  newPassword: HeaderAuthorizationNewPassword,
}

const closeModal = () => {
  props.close(false)
}
const currentComponent = computed(() => components[currentView.value])

const changeView = (view) => {
  currentView.value = view
}

watch(
  () => props.isVisible,
  (newVal) => {
    console.log(newVal)
    if (newVal) {
      currentView.value = props.view
    }
  },
)
</script>

<style lang="scss" scoped>
.authorization {
  max-height: 95dvh;
  overflow: auto;
  margin: auto 0;

  &::-webkit-scrollbar,
  &::-webkit-scrollbar-thumb,
  &::-webkit-scrollbar-track {
    display: none;
  }
}
</style>
