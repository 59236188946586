export const useAuth = () => useNuxtApp().$auth

export const useAuthState = () => {
  const authCookie = useCookie<boolean>("auth-enabled", {
    default: () => false,
  })
  const isAuthEnabled = computed(() => authCookie.value)
  const route = useRoute()

  if (
    "auth" in route.query &&
    ["true", "false"].includes(route.query.auth as string)
  ) {
    authCookie.value = JSON.parse((route.query.auth as string).toLowerCase())
  }

  return isAuthEnabled
}
