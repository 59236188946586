import { default as _91provider_934IvDICxzg8Meta } from "/var/www/frontend/html/backend/pages/auth/[provider].vue?macro=true";
import { default as indexFNSa9kin4XMeta } from "/var/www/frontend/html/pages/index.vue?macro=true";
import { default as nomination_45_91slug_9342JQ4oyBvFMeta } from "/var/www/frontend/html/pages/nomination-[slug].vue?macro=true";
import { default as ofertalYbGAaoc4qMeta } from "/var/www/frontend/html/pages/oferta.vue?macro=true";
import { default as participant_45form3rrCnFkfkhMeta } from "/var/www/frontend/html/pages/participant-form.vue?macro=true";
import { default as policyj90sKkqFSmMeta } from "/var/www/frontend/html/pages/policy.vue?macro=true";
import { default as resetB9aWDTdDlhMeta } from "/var/www/frontend/html/pages/reset.vue?macro=true";
import { default as _91slug_93yotPKb16L8Meta } from "/var/www/frontend/html/pages/special/[slug].vue?macro=true";
export default [
  {
    name: "auth-provider",
    path: "/auth/:provider()",
    component: () => import("/var/www/frontend/html/backend/pages/auth/[provider].vue")
  },
  {
    name: "index",
    path: "/",
    component: () => import("/var/www/frontend/html/pages/index.vue")
  },
  {
    name: "nomination-slug",
    path: "/nomination-:slug()",
    component: () => import("/var/www/frontend/html/pages/nomination-[slug].vue")
  },
  {
    name: "oferta",
    path: "/oferta",
    component: () => import("/var/www/frontend/html/pages/oferta.vue")
  },
  {
    name: "participant-form",
    path: "/participant-form",
    component: () => import("/var/www/frontend/html/pages/participant-form.vue")
  },
  {
    name: "policy",
    path: "/policy",
    component: () => import("/var/www/frontend/html/pages/policy.vue")
  },
  {
    name: "reset",
    path: "/reset",
    component: () => import("/var/www/frontend/html/pages/reset.vue")
  },
  {
    name: "special-slug",
    path: "/special/:slug()",
    component: () => import("/var/www/frontend/html/pages/special/[slug].vue")
  }
]