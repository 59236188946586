<template>
  <div class="authorization">
    <div v-if="!success">
      <h2>Восстановить доступ</h2>
      <form class="authorization__form" @submit.prevent>
        <UiInput
          v-model="formData.password"
          type="password"
          autocomplete="new-password"
          :class="{ 'input-error': errors.password }"
          placeholder="Пароль"
          @input="validatePasswordInput"
        />
        <div v-if="errors.password" class="error-message">
          {{ errors.password }}
        </div>
        <UiInput
          v-model="formData.confirmPassword"
          type="password"
          autocomplete="new-password"
          :class="{ 'input-error': errors.confirmPassword }"
          placeholder="Повторите пароль"
          @input="validateConfirmPassword"
        />
        <div v-if="errors.confirmPassword" class="error-message">
          {{ errors.confirmPassword }}
        </div>
        <div v-if="errors.response" class="error-message">
          {{ errors.response }}
        </div>
        <UiButton @click="handleChangePassword">изменить пароль</UiButton>
      </form>
    </div>
    <div v-else class="success">
      <h2>Пароль успешно изменен</h2>
      <p>Теперь вы можете авторизоваться, используя новый пароль</p>
      <UiButton class="btn" @click="goHome">домой</UiButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
const route = useRoute()
const code = route.query?.code
const goHome = () => navigateTo("/")
const { resetPassword } = useStrapiClient()
const success = ref(false)

if (!code) {
  goHome()
}

const formData = ref({
  code: code as string,
  password: "",
  confirmPassword: "",
})

const errors = ref({
  password: "",
  confirmPassword: "",
  response: "",
})

const validatePasswordInput = () => {
  if (!validatePassword(formData.value.password)) {
    errors.value.password =
      // eslint-disable-next-line max-len
      "Пароль должен быть от 8 до 16 символов, содержать только латинские буквы и хотя бы одну цифру."
  } else {
    errors.value.password = ""
  }
}

const validateConfirmPassword = () => {
  if (formData.value.password !== formData.value.confirmPassword) {
    errors.value.confirmPassword = "Пароли не совпадают."
  } else {
    errors.value.confirmPassword = ""
  }
}

const handleChangePassword = async () => {
  validatePasswordInput()
  validateConfirmPassword()
  if (errors.value.password || errors.value.confirmPassword) return

  const result = await resetPassword(
    formData.value.password,
    formData.value.code,
  )

  if (!result) {
    errors.value.response =
      // eslint-disable-next-line max-len
      "Вы не можете больше воспользоваться данной ссылкой, отправьте повторный запрос на сброс пароля."
  }

  success.value = true
}
</script>

<style scoped>
.input-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.authorization {
  font-size: 12px;
  color: $color-dark-gray;

  & > div {
    padding: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    margin-top: 40px;
    align-items: flex-start;
    p {
      text-align: center;
      width: 100%;
      span {
        color: $color-purple;
      }
    }

    .re-request {
      margin: 0 auto;
      text-decoration: underline;
    }
  }
  &__form-additional-btns {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
  }

  &__other-ways {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 14px;
    button {
      width: 100%;
      border-radius: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 40px;
      height: 88px;
      background: $primary-black;
      color: $white;
    }
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -20px;
}

.success {
  text-align: center;

  .btn {
    margin-top: 2rem;
  }
}
</style>
