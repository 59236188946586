<template>
  <div class="authorization">
    <h2>Войти</h2>
    <form class="authorization__form" @submit.prevent>
      <div class="input-container">
        <UiInput
          v-model="formData.email"
          :class="{ 'input-error': errors.email }"
          type="email"
          placeholder="Электронная почта"
          autocomplete="email"
          @input="validateEmailInput"
        />
        <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      </div>
      <div class="input-container">
        <UiInput
          v-model="formData.password"
          :class="{ 'input-error': errors.password }"
          type="password"
          placeholder="Пароль"
          autocomplete="current-password"
          @input="validatePasswordInput"
        />
        <div v-if="errors.password" class="error-message">
          {{ errors.password }}
        </div>
        <div v-if="errors.response" class="error-message">
          {{ errors.response }}
        </div>
      </div>
      <div class="authorization__form-additional-btns">
        <button @click="$emit('changeView', 'forgotPassword')">
          Не помню пароль
        </button>
        <button @click="$emit('changeView', 'register')">
          Ещё нет учетной записи? Зарегистрируйтесь
        </button>
        <UiButton @click="handleLogin">Войти</UiButton>
      </div>
    </form>
    <div class="authorization__other-ways">
      <a class="button" :href="getProviderUrl('yandex')" target="_blank">
        <span>Войти с помощью Яндекс</span>
        <NuxtImg src="/img/icon_yandex.svg" />
      </a>
      <a class="button" :href="getProviderUrl('vk')" target="_blank">
        <span>Войти с помощью VK ID</span>
        <NuxtImg src="/img/icon_vk_auth.svg" />
        <NuxtImg src="/img/icon_ok_auth.svg" />
        <NuxtImg src="/img/icon_mail_auth.svg" />
      </a>
      <!--
 <button>
        Войти с помощью Одноклассники <NuxtImg src="/img/icon_ok_auth.svg" />
      </button>
      <button>
        Войти с помощью ВКонтакте <NuxtImg src="/img/icon_vk_auth.svg" />
      </button> 
-->
    </div>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(["changeView", "close"])
const { getProviderUrl } = useStrapiClient()

const formData = ref({
  email: "",
  password: "",
})

const errors = ref({
  email: "",
  password: "",
  response: "",
})

const validateEmailInput = () => {
  if (!validateEmail(formData.value.email)) {
    errors.value.email = "Введите корректный адрес электронной почты."
  } else {
    errors.value.email = ""
  }
}

const validatePasswordInput = () => {
  if (!validatePassword(formData.value.password)) {
    errors.value.password =
      // eslint-disable-next-line max-len
      "Пароль должен быть от 8 до 16 символов, содержать только латинские буквы и хотя бы одну цифру."
  } else {
    errors.value.password = ""
  }
}

const { login } = useStrapiClient()

const handleLogin = async () => {
  validateEmailInput()
  validatePasswordInput()

  if (errors.value.email || errors.value.password) return

  // const result = await login(formData.value.email, formData.value.password)
  const result = await login(formData.value.email, formData.value.password)

  if (!result) {
    errors.value.response =
      "Не удалось войти, проверьте правильность пароля и попробуйте снова"

    return
  }

  emits("close")
}
</script>

<style lang="scss" scoped>
.authorization {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $color-dark-gray;
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    margin-top: 40px;
    align-items: flex-start;
  }
  &__form-additional-btns {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
    .button {
      text-align: left;
    }
  }

  &__other-ways {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 14px;
    .button {
      width: 100%;
      border-radius: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 40px;
      height: 88px;
      background: $primary-black;
      color: $white;
      gap: var(--gap, 1.45em);

      span {
        margin-right: auto;
      }
      img {
        height: var(--icon-size, 1.75em);
        width: var(--icon-size, 1.75em);
      }
    }

    @include sm {
      .button {
        --icon-size: 1.25em;
        --gap: 1em;
        height: 60px;
        padding: 19px 20px;
      }
    }
  }
}

.input-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 4px;
}

.error-message {
  color: red;
  font-size: 12px;
}

.input-error {
  border: 1px solid red;
}
</style>
