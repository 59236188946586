import { createGtm } from "@gtm-support/vue-gtm"

export default defineNuxtPlugin((nuxtApp) => {
  nuxtApp.vueApp.use(
    createGtm({
      id: "GTM-KNCMRJ",
      defer: true,
      compatibility: false,
      nonce: "2726sc7f26c",
      enabled: true,
      debug: false,
      loadScript: true,
      trackOnNextTick: true,
      vueRouter: useRouter(),
    }),
  )
})
