import type {
  Senate,
  ParticipantLead,
  ParticipantProfile,
} from "~/types/strapiContent"
import type { StrapiFile } from "~/types/common"
import type { StrapiResponseSingle } from "~/types/strapiData"

export const getNominations = async () => {
  const { data } = await useFetch("/api/getNominations")

  return data.value
}
export const getSenates = async () => {
  const { data } = await useFetch("/api/getSenates")

  return data.value
}
export const getParticipants = async () => {
  const { data } = await useFetch("/api/getParticipants")

  return data.value
}
export const sendSenate = async (senate: Senate, files?: StrapiFile[]) => {
  const { data } = await useFetch("/api/sendSenate", {
    method: "POST",
    body: {
      senate,
      files,
    },
  })

  return data.value
}
export const sendParticipant = async (
  participant: ParticipantProfile,
  files?: StrapiFile[],
) => {
  const formData = new FormData()

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append(`files.${file.name}`, file.file, file.name)
    })
  }
  formData.append("data", JSON.stringify(participant))

  try {
    return !!(
      await $fetch<StrapiResponseSingle<ParticipantProfile>>(
        `${useStrapiUrl()}/participant-profiles?populate=*`,
        {
          method: "POST",
          body: formData,
        },
      )
    )?.data
  } catch (err: any) {
    console.log(err)
    return false
  }
}
export const sendParticipantProfile = async (
  participant: ParticipantProfile,
  files?: StrapiFile[],
) => {
  const formData = new FormData()

  if (files && files.length > 0) {
    files.forEach((file) => {
      formData.append(`files.${file.name}`, file.file, file.name)
    })
  }
  formData.append("data", JSON.stringify(participant))

  try {
    return !!(
      await $fetch<StrapiResponseSingle<ParticipantProfile>>(
        `${useStrapiUrl()}/participant-profiles?populate=*`,
        {
          method: "POST",
          body: formData,
        },
      )
    )?.data
  } catch (err: any) {
    console.log(err)
    return false
  }
}
export const sendParticipantLead = async (participant: ParticipantLead) => {
  const { data } = await useFetch("/api/sendParticipant", {
    method: "POST",
    body: {
      participant,
    },
  })

  return data.value
}
export const getOferta = async () => {
  const { data } = await useFetch("/api/getOferta")

  return data.value
}
export const getReviews = async () => {
  const { data } = await useFetch("/api/getReviews")

  return data.value
}
export const getPartners = async () => {
  const { data } = await useFetch("/api/getPartners")

  return data.value
}
export const getHome = async () => {
  const { data } = await useFetch("/api/getHomePage", {
    method: "POST",
  })

  return data.value
}
export const getGlobals = async () => {
  const { data } = await useFetch("/api/getGlobals", {
    method: "POST",
  })

  return data.value
}

export const getPartnerBySlug = async (slug: string) => {
  const { data } = await useFetch(`/api/partner/${slug}`, {
    method: "GET",
  })

  return data
}

export async function getNominationBySlug(slug: string) {
  // if (!import.meta.client) return

  const { data } = await useFetch(`/api/nomination/${slug}`, {
    method: "GET",
  })

  return data
}

export async function getParticipantsByNomination(nomination: string) {
  const { data } = await useFetch(`/api/participants/${nomination}`, {
    method: "GET",
  })

  return data
}

export const getNews = async () => (await useFetch("/api/getNews"))?.data
