<template>
  <div class="input">
    <input
      ref="inputRef"
      :type="inputType"
      :placeholder="placeholder"
      :autocomplete="autocomplete"
      @input="updateValue"
    />
    <button
      v-if="type === 'password'"
      class="input__password"
      @click="togglePasswordVisibility"
    >
      <NuxtImg v-if="!passwordVisible" src="/img/icon_password_show.svg" />
      <NuxtImg v-else src="/img/icon_password_hide.svg" />
    </button>
  </div>
</template>

<script setup>
const props = defineProps({
  placeholder: {
    type: String,
    required: false,
  },
  type: {
    type: String,
    required: true,
  },
  autocomplete: {
    type: String,
    required: false,
  },
  modelValue: String,
})

const inputRef = ref(null)
const passwordVisible = ref(false)

const inputType = computed(() => {
  if (props.type === "password") {
    return passwordVisible.value ? "text" : "password"
  }
  return props.type
})

const togglePasswordVisibility = () => {
  if (props.type === "password") {
    passwordVisible.value = !passwordVisible.value
  }
}

const emit = defineEmits(["update:modelValue"])

const updateValue = (event) => {
  emit("update:modelValue", event.target.value)
}
</script>

<style lang="scss" scoped>
.input {
  width: 100%;
  height: 80px;
  display: flex;
  justify-content: center;
  align-items: center;
  background: $white;
  border-radius: 6px;
  padding: 0 32px;
  font-size: 12px;
  input {
    background: transparent;
    border: none;
    outline: none;
    width: 100%;
    height: 100%;
    @media screen and (max-width: 460px) {
      font-size: 16px;
    }
  }

  &__password {
    width: 27px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: transparent;
    border: none;
    cursor: pointer;
    img {
      width: 100%;
    }
  }

  @include sm {
    padding: 18px 20px;
    height: 60px;
  }
}
</style>
