<template>
  <div class="authorization">
    <h2>{{ confirmation ? "Подтвердите Email" : "Зарегистрироваться" }}</h2>
    <form v-if="!confirmation" class="authorization__form" @submit.prevent>
      <div class="authorization__form-inputs">
        <UiInput
          v-model="formData.email"
          type="email"
          :class="{ 'input-error': errors.email }"
          placeholder="Почта"
          autocomplete="email"
          @input="validateEmailInput"
        />
        <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
        <UiInput
          v-model="formData.password"
          type="password"
          autocomplete="new-password"
          :class="{ 'input-error': errors.password }"
          placeholder="Пароль"
          @input="validatePasswordInput"
        />
        <div v-if="errors.password" class="error-message">
          {{ errors.password }}
        </div>
        <UiInput
          v-model="formData.confirmPassword"
          type="password"
          autocomplete="new-password"
          :class="{ 'input-error': errors.confirmPassword }"
          placeholder="Повторите пароль"
          @input="validateConfirmPassword"
        />
        <div v-if="errors.confirmPassword" class="error-message">
          {{ errors.confirmPassword }}
        </div>
      </div>
      <div class="authorization__checkbox">
        <UiCheckbox v-model="checkedTerms" />
        <label>
          Ознакомлен с
          <NuxtLink :to="{ name: 'oferta' }"> положением о премии </NuxtLink>
        </label>
      </div>
      <div v-if="errors.terms" class="error-message">{{ errors.terms }}</div>
      <div v-if="errors.response" class="error-message">
        {{ errors.response }}
      </div>
    </form>
    <div v-else class="authorization__confirmation">
      <div class="text-12">
        <p>Мы выслали код подтверждения на вашу почту.</p>
        <p>
          Для завершения регистрации осталось только перейти по ссылке из письма
        </p>
      </div>
      <!--
 <UiInput
        v-model="confirmationCode"
        type="text"
        :class="{ 'input-error': errors.confirmationCode }"
        placeholder="Код подтверждения"
        @input="validateConfirmationCode"
      />
      <div
        v-if="errors.confirmationCode"
        style="margin-top: -15px"
        class="error-message"
      >
        {{ errors.confirmationCode }}
      </div> 
-->
    </div>
    <UiButton @click="handleRegistration">
      {{ confirmation ? "Закрыть" : "Зарегистрироваться" }}
    </UiButton>
  </div>
</template>

<script lang="ts" setup>
import type { UserCreditionals } from "~/types/auth"
import { useStrapiClient } from "~/backend/composables/strapiClient"

const emits = defineEmits(["close"])

const confirmation = ref(false)
const checkedTerms = ref(false)
const formData = ref({
  email: "",
  password: "",
  confirmPassword: "",
})
// const confirmationCode = ref("")
const errors = ref({
  email: "",
  password: "",
  confirmPassword: "",
  terms: "",
  response: "",
})

const validateEmailInput = () => {
  if (!validateEmail(formData.value.email)) {
    errors.value.email = "Введите корректный адрес электронной почты."
  } else {
    errors.value.email = ""
  }
}

const validatePasswordInput = () => {
  if (!validatePassword(formData.value.password)) {
    errors.value.password =
      // eslint-disable-next-line max-len
      "Пароль должен быть от 8 до 16 символов, содержать только латинские буквы и хотя бы одну цифру."
  } else {
    errors.value.password = ""
  }
}

const validateConfirmPassword = () => {
  if (formData.value.password !== formData.value.confirmPassword) {
    errors.value.confirmPassword = "Пароли не совпадают."
  } else {
    errors.value.confirmPassword = ""
  }
}

// const validateConfirmationCode = () => {
//   if (!validateCode(formData.value.code)) {
//     errors.value.confirmationCode = "Введите корректный 6-значный код."
//   } else {
//     errors.value.confirmationCode = ""
//   }
// }

const { register } = useStrapiClient()

const handleRegistration = async () => {
  if (confirmation.value) {
    emits("close")
    return
  }

  validateEmailInput()
  validatePasswordInput()
  validateConfirmPassword()

  if (!checkedTerms.value) {
    errors.value.terms = "Ознакомьтесь с положением о премии."

    return
  } else {
    errors.value.terms = ""
  }

  if (
    errors.value.email ||
    errors.value.password ||
    errors.value.confirmPassword ||
    !checkedTerms
  )
    return

  const creditionals: UserCreditionals = {
    username: trimAfterMatch(formData.value.email, "@"),
    password: formData.value.password,
    email: formData.value.email,
  }

  const result = await register(creditionals)

  if (result.data?.user) {
    confirmation.value = true

    return
  }

  errors.value.response = result?.error?.message || "Произошла ошибка"
}
</script>

<style lang="scss" scoped>
.authorization {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $color-dark-gray;
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    margin: 40px 0 30px;
    align-items: flex-start;
  }

  &__confirmation {
    display: flex;
    flex-direction: column;
    margin: 40px 0 30px;
    width: 100%;
    gap: 30px;
    p {
      text-align: center;
    }
  }

  &__form-inputs {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    align-items: flex-start;
  }
  &__checkbox {
    display: flex;
    gap: 16px;
    a {
      color: $color-purple;
      cursor: pointer;
    }
  }
}

.input-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>
