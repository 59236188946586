export const validatePhone = (phone) => {
   const phoneRegex = /^\+7 \(\d{3}\) \d{3}-\d{2}-\d{2}$/
   return phoneRegex.test(phone)
}

export const validatePassword = (password) => {
   const passwordRegex = /^(?=.*[A-Za-z])(?=.*\d)[A-Za-z\d]{8,16}$/
   return passwordRegex.test(password)
}

export const validateEmail = (email) => {
   const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/
   return emailRegex.test(email)
}

export const validateCode = (code) => {
   const codeRegex = /^\d{6}$/
   return codeRegex.test(code)
}
