<template>
  <div class="authorization" :class="{ submitted: submit }">
    <h2 v-if="submit">
      {{ !submit ? "Стать участником" : "Заявка отправлена" }}
    </h2>
    <div v-if="!submit" class="authorization__form">
      <UiInput
        v-model="formData.username"
        type="text"
        :class="{ 'input-error': errors.fullName }"
        placeholder="ФИО"
        @input="validateFullName"
      />
      <div v-if="errors.username" class="error-message">
        {{ errors.username }}
      </div>
      <UiInput
        v-model="formData.position"
        type="text"
        :class="{ 'input-error': errors.position }"
        placeholder="Должность"
        @input="validatePosition"
      />
      <div v-if="errors.position" class="error-message">
        {{ errors.position }}
      </div>
      <UiMaskInput
        v-model="formData.phone"
        type="text"
        :class="{ 'input-error': errors.phone }"
        placeholder="Телефон"
        @input="validatePhoneInput"
      />
      <div v-if="errors.phone" class="error-message">{{ errors.phone }}</div>
      <UiInput
        v-model="formData.email"
        type="text"
        :class="{ 'input-error': errors.email }"
        placeholder="Почта"
        @input="validateEmailInput"
      />
      <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      <UiInput
        v-model="formData.company"
        type="text"
        :class="{ 'input-error': errors.company }"
        placeholder="Название компании"
        @input="validateCompany"
      />
      <div v-if="errors.company" class="error-message">
        {{ errors.company }}
      </div>
      <div class="flex-column gap-14 mt-15px">
        <div class="authorization__checkbox">
          <UiCheckbox v-model="formData.agreeWithPolicy" />
          <label>
            Ознакомлен с
            <NuxtLink :to="{ name: 'oferta' }"> положением о премии </NuxtLink>
          </label>
        </div>
        <div v-if="errors.terms" class="error-message">{{ errors.terms }}</div>
      </div>
    </div>
    <div v-else class="flex-column-center gap-40 mt-30px">
      <p class="text-16 text-center">
        Благодарим за заявку! Ответное <br />
        письмо будет отправлено на почту <br />
        <span class="text-purple">{{ formData.email }}</span>
        <br />
        <span class="text-12">почту подтвердить нужно в течении 24 часов</span>
      </p>
    </div>
    <div v-if="errorFromApi" class="error-message">{{ errorFromApi }}</div>
    <UiButton v-if="!submit" class="mt-15px" @click="handleSubmit">
      Отправить
    </UiButton>
  </div>
</template>

<script setup>
const props = defineProps({
  isVisible: {
    type: Boolean,
    required: true,
  },
})
const emits = defineEmits(["changeView", "close"])
const submit = ref(false)
const errorFromApi = ref("")

const formData = ref({
  username: "",
  position: "",
  phone: "",
  email: "",
  company: "",
  agreeWithPolicy: false,
})

const errors = ref({
  username: "",
  position: "",
  phone: "",
  email: "",
  company: "",
  terms: "",
})

const validateFullName = () => {
  errors.value.username = formData.value.username ? "" : "Введите ФИО"
}

const validatePosition = () => {
  errors.value.position = formData.value.position ? "" : "Введите должность"
}

const validatePhoneInput = () => {
  if (!validatePhone(formData.value.phone)) {
    errors.value.phone = "Введите корректный номер телефона."
  } else {
    errors.value.phone = ""
  }
}

const validateEmailInput = () => {
  if (!validateEmail(formData.value.email)) {
    errors.value.email = "Введите корректный адрес электронной почты."
  } else {
    errors.value.email = ""
  }
}

const validateCompany = () => {
  errors.value.company = formData.value.company
    ? ""
    : "Введите название компании"
}

const handleSubmit = async () => {
  validateFullName()
  validatePosition()
  validatePhoneInput()
  validateEmailInput()
  validateCompany()

  if (!formData.value.agreeWithPolicy) {
    errors.value.terms = "Ознакомьтесь с положением о премии."
  } else {
    errors.value.terms = ""
  }

  if (
    !errors.value.username &&
    !errors.value.position &&
    !errors.value.phone &&
    !errors.value.email &&
    !errors.value.company &&
    !errors.value.terms
  ) {
    const data = {
      username: formData.value.username,
      position: formData.value.position,
      phone: formData.value.phone,
      email: formData.value.email,
      company: formData.value.company,
    }
    try {
      const response = await sendParticipantLead(data)
      if (response?.data) {
        submit.value = true
      }
    } catch (error) {
      errorFromApi.value = formErrorHandler(error.error?.message)
    }
  }
}

watch(
  () => props.isVisible,
  (newVal) => {
    if (newVal) {
      submit.value = false
    }
  },
)
</script>

<style lang="scss" scoped>
.authorization {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $color-dark-gray;
  max-height: 95dvh;
  overflow: auto;
  margin: auto 0;
  &.submitted {
    padding: 100px 60px;
  }
  &::-webkit-scrollbar {
    width: 6px;
  }
  &::-webkit-scrollbar-thumb {
    background: $primary-black;
    border-radius: 10px;
  }
  &::-webkit-scrollbar-track {
    border-radius: 10px;
  }
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 14px;
    margin: 40px 0 30px;
    align-items: flex-start;
  }
  &__checkbox {
    display: flex;
    gap: 16px;
    a {
      color: $color-purple;
      cursor: pointer;
    }
  }
}

.input-error {
  border-color: red;
}
.error-message {
  color: red;
  font-size: 12px;
  margin-top: -10px;
}
</style>
