<template>
  <div class="authorization">
    <h2>Восстановить доступ</h2>
    <form v-if="step === 1" class="authorization__form" @submit.prevent>
      <p class="text-16">
        Введите адрес почты, указанный<br />
        в вашем профиле
      </p>
      <UiInput
        v-model="email"
        type="email"
        :class="{ 'input-error': errors.email }"
        placeholder="Почта"
        @input="validateMail"
      />
      <div v-if="errors.email" class="error-message">{{ errors.email }}</div>
      <UiButton @click="nextStep">Получить код</UiButton>
    </form>
    <div v-if="step === 2" class="authorization__form">
      <p class="text-16">
        Перейдите по ссылке, которую мы отправили по адресу
        <span>{{ email }}</span>
      </p>
      <!-- <button class="re-request">запросить код повторно</button> -->
      <UiButton @click="nextStep">закрыть</UiButton>
    </div>
  </div>
</template>

<script lang="ts" setup>
const emits = defineEmits(["close"])
const step = ref(1)
const { forgotPassword } = useStrapiClient()

const email = ref<string>("")
const errors = ref({
  email: "",
})

const validateMail = () => {
  if (!validateEmail(email.value)) {
    errors.value.email = "Введите корректный адрес электронной почты."
  } else {
    errors.value.email = ""
  }
}

const nextStep = async () => {
  if (step.value !== 1) emits("close")

  validateMail()
  if (errors.value.email) return

  await forgotPassword(email.value)
  step.value++
}
</script>

<style scoped>
.input-error {
  border: 1px solid red;
}
.error-message {
  color: red;
  font-size: 12px;
}
</style>

<style lang="scss" scoped>
.authorization {
  padding: 40px;
  display: flex;
  flex-direction: column;
  align-items: center;
  font-size: 12px;
  color: $color-dark-gray;
  h2 {
    font-size: 24px;
    font-weight: 400;
  }
  &__form {
    display: flex;
    flex-direction: column;
    width: 100%;
    gap: 30px;
    margin-top: 40px;
    align-items: flex-start;
    p {
      text-align: center;
      width: 100%;
      span {
        color: $color-purple;
      }
    }

    .re-request {
      margin: 0 auto;
      text-decoration: underline;
    }
  }
  &__form-additional-btns {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    gap: 30px;
    width: 100%;
  }

  &__other-ways {
    margin-top: 40px;
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
    gap: 14px;
    button {
      width: 100%;
      border-radius: 40px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 24px 40px;
      height: 88px;
      background: $primary-black;
      color: $white;
    }
  }
}

.error-message {
  color: red;
  font-size: 12px;
  margin-top: -20px;
}
</style>
