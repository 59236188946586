<template>
  <div class="share-container flex flex-center gap-14"  @click="handleOpenShare">
    <div class="share" :class="{inHeader: inHeader}">
      <div class="share-nav" :class="{ 'share-nav--open': shareNav, inFooter: !inHeader }">
        <NuxtLink class="share-link" :to="vkShareLink" target="_blank">
          <div class="icon">
            <NuxtImg src="/img/icon-share-vk.svg"/>
          </div>
          Вконтакте
        </NuxtLink>
        <NuxtLink class="share-link" :to="okShareLink" target="_blank">
          <div class="icon">
            <NuxtImg src="/img/icon-share-ok.svg"/>
          </div>
          Одноклассники
        </NuxtLink>
        <NuxtLink class="share-link" :to="telegramShareLink" target="_blank">
          <div class="icon">
            <NuxtImg src="/img/icon-share-telegram.svg"/>
          </div>
          Telegram
        </NuxtLink>
        <div class="share-link" @click="copyLink">
          <div class="icon">
            <NuxtImg src="/img/icon-share-copy.svg"/>
          </div>
          <span class="text-underline">Скопировать ссылку</span>
        </div>
      </div>
    </div>
    <slot />
  </div>
</template>

<script setup>
defineProps({
  inHeader: {
    type: Boolean,
    default: false
  }
})
const siteUrl = "https://edtech.kp.ru/"
const vkShareLink = `https://vk.com/share.php?url=${encodeURIComponent(siteUrl)}`
const okShareLink = `https://connect.ok.ru/offer?url=${encodeURIComponent(siteUrl)}`
const telegramShareLink = `https://telegram.me/share/url?url=${encodeURIComponent(siteUrl)}`

const shareNav = ref(false)

const handleOpenShare = (event) => {
  event.stopPropagation()
  shareNav.value = !shareNav.value
}

const copyLink = async () => {
  try {
    await navigator.clipboard.writeText(siteUrl)
  } catch (err) {
    console.error('Не удалось скопировать ссылку: ', err)
  }
}

const handleClickOutside = (event) => {
  const shareNavElement = document.querySelector('.share-nav')
  if (shareNav.value && !shareNavElement.contains(event.target)) {
    shareNav.value = false
  }
}

onMounted(() => {
  document.addEventListener('click', handleClickOutside)
})

onUnmounted(() => {
  document.removeEventListener('click', handleClickOutside)
})
</script>

<style lang="scss" scoped>
.share-container {
  cursor: pointer;
  margin-top: auto;
}
.share {
  position: relative;
  width: 30px;
  height: 30px;
  border: 1px solid #5738cf;
  border-radius: 50%;
  cursor: pointer;
  transition: 0.5s;
  &::before {
    display: block;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: auto;
    position: absolute;
    content: url("@/public/img/icon_share.svg");
  }
  &:hover {
    background-color: #5738cf;
    &::before {
      filter: brightness(0) invert(1);
    }
  }

  &.inHeader {
    border: 1px solid $white;
    &::before {
      filter: brightness(0) invert(1);
    }
    &:hover {
      background-color: $white;
      &::before {
        filter: brightness(0) invert(0);
      }
    }
  }
}

.share-nav {
  padding: 30px;
  position: absolute;
  width: 236px;
  background-color: #f5f6fb;
  border-radius: 20px;
  display: none;
  top: 130%;
  left: 0;
  flex-direction: column;
  z-index: 4;
  box-shadow: 0 0 10px #00000010;
  gap: 10px;
  cursor: default;
  &--open {
    transition: ease-in-out 0.335s;
    display: flex;
  }

  &.inFooter {
    left: -206px;
  }

  @include sm {
    width: 185px;
    padding: 10px;
    gap: 8px;

    &.inFooter {
      left: 0;
    }
  }
}

.share-link {
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
  font-size: 12px;
  color: $color-dark-gray;
  .icon {
    width: 30px;
    height: 30px;
    border-radius: 50%;
    border: 1px solid $color-dark-gray;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
    img {
      width: 90%;
      height: 90%;
    }
  }

  @include sm {
    font-size: 11px;
    .icon {
      width: 25px;
      height: 25px;
    }
  }
}
</style>
