import type { RouteLocationAsRelativeGeneric } from "vue-router"
import type { Participant } from "~/types/strapiContent"

export const getNominationLink = (slug: string) => {
  if (!slug) return

  return <RouteLocationAsRelativeGeneric>{
    name: "nomination-slug",
    params: {
      slug,
    },
  }
}

export const getPartnerLink = (slug?: string) => {
  if (!slug) return

  return <RouteLocationAsRelativeGeneric>{
    name: "special-slug",
    params: {
      slug,
    },
  }
}

export const trimAfterMatch = (str: string, needle: string, keep?: Boolean) => {
  const pos = str.toLowerCase().indexOf(needle.toLowerCase())

  if (pos === -1) return str

  return str.slice(0, keep ? pos + needle.length : pos)
}

export const voteConfirmationData = () =>
  useState<Participant | undefined>("voteConfirmationData")
