import type { AuthSession, UserVote } from "~/types/auth"

export default defineNuxtPlugin(async (nuxtApp) => {
  if (nuxtApp.payload.error) {
    return {}
  }

  const { data: session, refresh: updateSession } =
    await useFetch<AuthSession>("/api/auth/session")
  const { data: globals } = await useFetch("/api/getGlobals", {
    pick: ["attributes"],
    deep: false,
  })

  const authForced = useAuthState() // should be removed after tests

  const loggedIn = computed<boolean>(() => !!session.value?.token)
  const authEnabled = computed<boolean>(
    () => !!globals.value?.attributes.isAuthEnabled || authForced.value,
  )
  const voteEnabled = computed<boolean>(
    () => !!globals.value?.attributes.isVoteEnabled || authForced.value,
  )
  const redirectTo = useState("authRedirect")
  const votedFor = () => {
    const now = new Date().getDate()
    const expAt = new Date()
    expAt.setDate(now + 1)
    expAt.setHours(0, 0, 0, 0)

    return useCookie<UserVote[]>("voted-for", {
      default: () => [],
      expires: expAt,
    })
  }

  /**
   * Add global route middleware to protect pages using:
   *
   * definePageMeta({
   *  auth: true
   * })
   */
  //

  addRouteMiddleware(
    "auth",
    (to) => {
      if (to.meta.auth && !loggedIn.value) {
        redirectTo.value = to.path
        return "/auth/login"
      }
    },
    { global: true },
  )

  const currentRoute = useRoute()

  if (import.meta.client) {
    watch(loggedIn, async (loggedIn) => {
      if (!loggedIn && currentRoute.meta.auth) {
        redirectTo.value = currentRoute.path
        await navigateTo("/auth/login")
      }
    })
  }

  if (loggedIn.value && currentRoute.path === "/auth/login") {
    await navigateTo(redirectTo.value || "/")
  }

  return {
    provide: {
      auth: {
        authEnabled,
        voteEnabled,
        loggedIn,
        session,
        votedFor,
        redirectTo,
        updateSession,
      },
    },
  }
})
