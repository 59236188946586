<template>
  <div @click="toggleChecked" class="checkbox">
    <NuxtImg v-if="isChecked" src="/img/icon_checked.svg" />
  </div>
</template>

<script setup>
const props = defineProps({
  modelValue: {
    type: Boolean,
    required: true,
  },
});

const emit = defineEmits(['update:modelValue']);

const isChecked = ref(props.modelValue);

watch(() => props.modelValue, (newValue) => {
  isChecked.value = newValue;
});

const toggleChecked = () => {
  isChecked.value = !isChecked.value;
  emit('update:modelValue', isChecked.value);
};
</script>

<style scoped lang="scss">
.checkbox {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
  border: 2px solid $color-dark-gray;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  img {
    width: 13px;
  }
}
</style>
