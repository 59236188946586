/* eslint-disable no-console */
import type { LocationQuery } from "vue-router"
import type { UserCreditionals, UserVote } from "~/types/auth"
export const useStrapiUrl = () => {
  //   const config = import.meta.server
  //     ? useRuntimeConfig()
  //     : useRuntimeConfig().public
  const config = useRuntimeConfig().public
  return `${config.strapi.url}${config.strapi.prefix}`
}
export const useStrapiMediaUrl = (path?: string) => {
  if (!path) return

  return `${useRuntimeConfig().public.strapi.url}${path}`
}
export const useStrapiClient = () => {
  const auth = useAuth()
  const url = useStrapiUrl()
  const setVoted = (token?: string) => {
    if (!import.meta.client || !token) return

    auth.updateSession().then(async () => {
      const cookie = auth.votedFor()
      const voted = await $fetch("/api/getVotes", {
        method: "POST",
        body: {
          token,
        },
      })

      if (voted && voted.length > 0) {
        voted.forEach((el) => {
          cookie.value.push({
            nomination: el.attributes.nomination,
            participant: el.attributes.participant,
          })
        })
      }
    })
  }
  const login = async (identifier: string, password: string) => {
    const result = await $fetch("/api/auth/login", {
      method: "POST",
      body: {
        identifier,
        password,
      },
    })
    const token = result?.data?.jwt
    setVoted(token)
    // Promise.all([auth.updateSession(), setVoted(token)])

    return !!token
  }
  const logout = async () => {
    await $fetch("/api/auth/logout", {
      method: "POST",
    })
    auth.votedFor().value = []
    await auth.updateSession()
  }
  const register = async (creditionals: UserCreditionals) => {
    return await $fetch("/api/auth/register", {
      method: "POST",
      body: {
        data: creditionals,
      },
    })
  }
  const forgotPassword = async (email: string) => {
    try {
      return await $fetch("/api/auth/forgot", {
        method: "POST",
        body: { email },
      })
    } catch (err) {
      console.log(err)
    }
  }
  const resetPassword = async (password: string, code: string) => {
    const result = await $fetch("/api/auth/reset", {
      method: "POST",
      body: {
        password,
        code,
      },
    })

    return !!result?.data?.jwt
  }
  const vote = async (participant: string, nomination: string) => {
    const voted = auth.votedFor()
    if (
      !participant ||
      !nomination ||
      voted.value.find((e) => e.nomination === nomination)
    )
      return null

    const result = await $fetch("/api/vote", {
      method: "POST",
      body: {
        vote: <UserVote>{
          participant,
          nomination,
        },
      },
    })

    if (result) {
      voted.value.push({
        participant,
        nomination,
      })
    }

    return result
  }

  const getProviderUrl = (provider: string) => {
    return `${url}/connect/${provider}`
  }

  const authProvider = async (provider: string, params: LocationQuery) => {
    if (!params?.access_token) return
    const token = ref<string | undefined>()

    try {
      const result = await $fetch("/api/auth/provider", {
        method: "POST",
        body: {
          provider,
          params,
        },
      })

      token.value = result?.data?.jwt
      setVoted(token.value)
    } catch (err: any) {
      console.log(err)
    }

    return !!token.value
  }

  return {
    login,
    logout,
    register,
    forgotPassword,
    resetPassword,
    vote,
    getProviderUrl,
    authProvider,
  }
}
